<template>

  <div class="container">
		<div class="row" v-if="!edit">
			<div class="col-12 col-lg-8">
				<h1>Tiedotteet</h1>
			</div>
			<div class="col-12 col-lg-4 text-right">
				<button class="btn btn-new btn-lg d-inline-block" v-if="!loading" v-on:click.stop.prevent="newitem"><i class="fas fa-plus"></i> Luo uusi tiedote</button>
			</div>
			<div class="col-12">

        <b-overlay v-if="loading" class="list-loading" variant="white" show no-wrap></b-overlay>

				<div class="filters">
          <div class="filter">
            <select v-model="filters.channel">
              <option value="">Kaikki kanavat</option>
              <option value="email">Sähköposti</option>
              <option value="sms">Tekstiviesti</option>
              <option value="channel">Web</option>
            </select>
          </div>
          <div class="filter">
            <select v-model="filters.site_id">
              <option value="">Kaikki kohteet</option>
              <option v-for="(site) in sites" :key="site.id" :value="site.id">{{ site.name }}</option>
            </select>
          </div>
          <div class="filter">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="expired" value="1" v-model="filters.expired">
              <label class="custom-control-label" for="expired">Näytä vanhentuneet</label>
            </div>
          </div>
          <button type="button" class="btn btn-sm btn-primary" v-on:click="load()">Suodata</button>
				</div>

				<b-alert variant="danger" :show="error? true : false">{{ error }}</b-alert>

				<table class="admin-list" v-if="!loading && visible > 0">
					<tr>
						<th>PVM <i class="fas fa-chevron-down" v-on:click="sort = 'created:asc'" v-if="sort != 'created:asc'"></i> <i class="fas fa-chevron-up" v-on:click="sort = 'created:desc'" v-if="sort != 'created:desc'"></i></th>
						<th>Kohdennus</th>
						<th>Otsikko</th>
						<th>Lähetystavat</th>
						<th>Vanhenee</th>
						<th></th>
					</tr>
					<tr v-for="(item, index) in items" :key="item.id" :id="['bulletin-' + item.id]">
						<td class="w120"><p><span class="date">{{ item.created | formatDate }}</span><span v-if="!item.is_draft" class="status green">Lähetetty</span><span v-if="item.is_draft" class="status orange">Luonnos</span></p></td>
						<td class=""><p>tyyppi</p></td>
						<td class="text"><p><strong>{{ item.name }}</strong></p></td>
						<td><p><i class="fas fa-at" v-if="item.send_email"></i> <i class="fas fa-sms" v-if="item.send_sms"></i> <i class="fas fa-laptop" v-if="item.send_channel"></i></p></td>
						<td><p>{{ item.message_end | formatDate }}</p></td>
						<td class="text-right"><a class="btn btn-sm btn-primary" v-on:click.stop.prevent="edititem(index)">Näytä</a></td>
					</tr>
				</table>

				<b-alert class="my-3" variant="info" :show="!loading && items.length == 0">Tiedotteita ei löydetty!</b-alert>

				<ul class="pagination pagination-sm justify-content-center my-4" v-if="!loading && pages > 0">
					<li v-for="n in pages" :key="n" class="page-item" :class="[page == (n-1) ? 'active' : '']"><a class="page-link" href="#" v-on:click.stop.prevent="showpage(n-1)">{{ n }}</a></li>
				</ul>

			</div>
		</div>
		<div class="row" v-if="edit">
			<div class="col-12 pt-1">
        <b-overlay variant="white" :show="sending" no-wrap></b-overlay>
        <form @submit.prevent="saveedit" class="edit-form">
          <div class="row">
            <div class="col-12 col-lg-6">

              <h1 v-if="typeof edit_data.id == 'undefined'">Uusi tiedote</h1>
              <h1 v-if="typeof edit_data.id == 'number'">#{{ edit_data.id }} {{ edit_data.created | formatDateTime }}</h1>

              <div class="form-group row">
                <div class="col-6">
                  <h4>Valitse vastaanottajat</h4>

                  <div class="check-wrap">
                    <div class="custom-control custom-radio">
                      <input type="radio" id="type-1" class="custom-control-input" value="1" v-model="edit_data.type">
                      <label class="custom-control-label" for="type-1">Kaikki</label>
                    </div>
                    <div class="custom-control custom-radio">
                      <input type="radio" id="type-2" class="custom-control-input" value="2" v-model="edit_data.type">
                      <label class="custom-control-label" for="type-2">Sopimusasiakas</label>
                    </div>
                    <div class="custom-control custom-radio">
                      <input type="radio" id="type-3" class="custom-control-input" value="3" v-model="edit_data.type">
                      <label class="custom-control-label" for="type-3">Kohteet</label>
                    </div>
                    <div class="custom-control custom-radio">
                      <input type="radio" id="type-4" class="custom-control-input" value="4" v-model="edit_data.type">
                      <label class="custom-control-label" for="type-4">Asukkaat</label>
                    </div>
                  </div>

                </div>
                <div class="col-6">
                  <h4>Valitse lähetystavat</h4>

                  <div class="check-wrap">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="send_email" value="1" v-model="edit_data.send_email">
                      <label class="custom-control-label" for="send_email">Sähköposti</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="send_sms" value="1" v-model="edit_data.send_sms">
                      <label class="custom-control-label" for="send_sms">Tekstiviesti (SMS)</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="send_channel" value="1" v-model="edit_data.send_channel">
                      <label class="custom-control-label" for="send_channel">Web-palveluun</label>
                    </div>
                  </div>

                </div>
              </div>

              <div class="form-group" v-if="edit_data.type == 3">
                <div>
                  <multiselect v-model="edit_data.rec_sites" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :hide-selected="true" :showLabels="false" placeholder="Valitse kohteet" :options="sites.map(site => site.id)" :custom-label="opt => sites.find(x => x.id == opt).name">
                    <template slot="noResult">Kohteita ei löydetty.</template>
                  </multiselect>
                </div>
              </div>

              <div class="form-group" v-if="edit_data.type == 2">
                <div>
                  <multiselect v-model="edit_data.rec_clients" @search-change="searchClients" :loading="msLoading" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :hide-selected="true" :showLabels="false" placeholder="Valitse asiakkaat" :options="clients" track-by="id" label="name">
                    <template slot="noResult">Asiakkaita ei löydetty.</template>
                    <template slot="noOptions">Kirjoita hakeaksesi.</template>
                  </multiselect>
                </div>
              </div>

              <div class="form-group" v-if="edit_data.type == 4">
                <div>
                  <multiselect v-model="edit_data.rec_users" @search-change="searchUsers" :loading="msLoading" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :hide-selected="true" :showLabels="false" placeholder="Valitse asukkaat" :options="users" track-by="id" label="name">
                    <template slot="noResult">Asukkaita ei löydetty.</template>
                    <template slot="noOptions">Kirjoita hakeaksesi.</template>
                  </multiselect>
                </div>
              </div>

              <div class="form-group">
                <label class="form-label">Otsikko</label>
                <input type="text" v-model="edit_data.name" class="form-control" required />
              </div>

              <div class="form-group row" v-if="0">
                <label class="col-sm-2 col-form-label">Kohde</label>
                <div class="col-sm-10">
                  <select v-model="edit_data.site_id" class="form-control">
                    <option v-for="(site) in sites" :key="site.id" :value="site.id">{{ site.name }}</option>
                  </select>
                </div>
              </div>

              <div class="form-group gray-box" v-if="edit_data.send_email == 1">
                <div class="box-icon"><i class="fas fa-at"></i></div>
                <label class="form-label">Viesti</label>
                <editor
                v-model="edit_data.email_body"
                :disabled="edit_data.is_draft == 0"
                api-key="vv3ycle7w4b6439kk6j6jcv36kotwc7lliki5us85l165ifs"
                :init="{
                  height: 400,
                  menubar: false,
                  plugins: [
                  'advlist autolink lists link image charmap anchor',
                  'visualblocks code',
                  'paste code help'
                  ],
                  toolbar:
                  'formatselect | bold italic | alignleft aligncenter alignright alignjustify | code removeformat'
                  }"
                  />

                </div>

                <div class="form-group gray-box" v-if="edit_data.send_sms == 1">
                  <div class="box-icon"><i class="fas fa-sms"></i></div>
                  <label class="form-label">Viesti</label>
                  <textarea v-model="edit_data.sms_body" class="form-control" maxlength="320" :disabled="edit_data.is_draft == 0"></textarea>
                  <p class="text-right mt-2 mb-0">{{ getsmslength }} / 320 merkkiä</p>
                </div>

                <div class="form-group gray-box" v-if="edit_data.send_channel == 1">
                  <div class="box-icon"><i class="fas fa-laptop"></i></div>
                  <label class="form-label">Tiedote</label>
                  <textarea v-model="edit_data.message" class="form-control tall"></textarea>
                  <div class="row mt-3">
                    <div class="col-6">
                      <label class="form-label">Julkaistaan</label>
                      <input type="datetime-local" v-model="edit_data.message_start" class="form-control" />
                    </div>
                    <div class="col-6">
                      <label class="form-label">Vanhenee</label>
                      <input type="datetime-local" v-model="edit_data.message_end" class="form-control" />
                    </div>
                  </div>
                </div>

                <div class="form-group row" v-if="0">
                  <div class="col-12 check-wrap">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="is_draft" value="1" v-model="edit_data.is_draft">
                      <label class="custom-control-label" for="is_draft">Vedos</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" v-if="0">
                  <div class="col-12 check-wrap">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="send" value="1" v-model="edit_data.send">
                      <label class="custom-control-label" for="send">Lähetä viesti</label>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-12 col-lg-6">
                <div class="form-group text-right">
                  <button type="button" class="btn btn-delete" v-if="typeof edit_data.id != 'undefined'" @click="confirmDelete = true"><i class="fas fa-trash"></i> POISTA</button>
                  <button type="button" class="btn btn-light btn-lg ml-2" v-if="typeof edit_data.id != 'undefined'" v-on:click="canceledit()">TAKAISIN</button>
                  <button type="button" class="btn btn-light btn-lg ml-2" v-if="typeof edit_data.id == 'undefined'" v-on:click="canceledit()">PERUUTA</button>
                  <button type="submit" class="btn btn-new btn-lg ml-2"><i class="fas fa-save"></i> TALLENNA</button>
                  <button type="button" class="btn btn-primary btn-lg ml-2" v-on:click="sendmessage()" v-if="typeof edit_data.id != 'undefined' && edit_data.is_draft == 1"><i class="fas fa-paper-plane"></i> LÄHETÄ</button>
                  <button type="button" class="btn btn-success btn-lg ml-2 cursor-default" v-if="typeof edit_data.id != 'undefined' && edit_data.is_draft == 0"><i class="fas fa-check"></i> LÄHETETTY</button>
                  <p v-if="edit_data.is_draft == 0" class="text-right mt-3 sent-on">{{ edit_data.sent_by_name }}, {{ edit_data.sent | formatDateTime }}</p>
                </div>
                <div class="form-group mt-5" v-if="typeof edit_data.id != 'undefined' && edit_data.is_draft == 1 && (edit_data.send_email == 1 || edit_data.send_sms == 1)">
                  <div class="form-group px-0 offset-lg-3 col-lg-9">
                    <label class="form-label">Testaa viestin lähetys</label>
                    <div class="form-group row" v-if="edit_data.send_email == 1">
                      <div class="col-8">
                        <input type="email" v-model="test_email" class="form-control form-control-sm" />
                      </div>
                      <div class="col-4">
                        <button type="button" class="btn btn-sm btn-primary" v-on:click="sendTestMessage('email')">Lähetä</button>
                      </div>
                    </div>
                    <div class="form-group row" v-if="edit_data.send_sms == 1">
                      <div class="col-8">
                        <input type="phone" v-model="test_sms" class="form-control form-control-sm" />
                      </div>
                      <div class="col-4">
                        <button type="button" class="btn btn-sm btn-primary" v-on:click="sendTestMessage('sms')">Lähetä</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </form>

          <b-overlay :show="confirmDelete" variant="white" no-wrap no-center>
            <template #overlay>
              <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label" class="text-center p-5" >
                <p><strong id="form-confirm-label">Haluatko varmasti poistaa tiedotteen "{{ edit_data.name }}" ?</strong></p>
                <div class="text-center">
                  <b-button variant="outline-danger" class="mr-3" @click="confirmDelete = false">Peruuta</b-button>
                  <b-button variant="outline-success" @click="senddelete()">OK</b-button>
                </div>
              </div>
            </template>
          </b-overlay>

			</div>
		</div>
	</div>

</template>

<script>
import Editor from '@tinymce/tinymce-vue'
var debounce = require('lodash.debounce');

export default {
  components: {
    'editor': Editor
  },
  data: function () {
		return {
			loading: true,
      msLoading: false,
			error: false,
			items: [],
			sites: [],
      users: [],
      clients: [],
      filters: {
        channel: '',
        site_id: '',
        expired: true,
      },
			edit: false,
			edit_data: [],
      confirmDelete: false,
			sending: false,
			visible: 0,
			perpage: 10,
			pages: 0,
			page: 0,
			sort: 'created:desc',
      test_email: this.$parent.$parent.email,
      test_sms: this.$parent.$parent.phone
		}
	},
	created: function () {
		var vm = this;
		this.axios.get('/api/sites?limit=500')
		.then(function (response) {
			if(response.status == 200) {
				vm.sites = response.data.results;
			}
		})
		.catch(function (error) {
			console.log(error);
		})
    vm.load();
    if(typeof vm.$route.query.id != 'undefined') {
      if(vm.$route.query.id == 'new') vm.newitem();
			//else vm.edititem(this.$route.query.id);
		}
	},
	methods: {
		load: function () {
			var vm = this;
			vm.loading = true;
			var sort = vm.sort.split(':');
			this.axios.get('/api/bulletins?limit=' + vm.perpage + '&start=' + (vm.page * vm.perpage) + '&sort=' + sort[0] + '&dir=' + sort[1] + '&filters=' + JSON.stringify(vm.filters))
			.then(function (response) {
				if(response.status == 200) {
					vm.loading = false;
					vm.pages = Math.ceil(response.data.total / vm.perpage);
					vm.items = response.data.results;
					vm.visible = response.data.results.length;
				} else {
					vm.error = response.data.message;
				}
			})
			.catch(function (error) {
				vm.error = error;
				vm.items = [];
				vm.pages = 0;
				vm.loading = false;
			})
		},
		showpage: function (id) {
			this.page = id;
		},
    sendTestMessage: function(type) {
      var vm = this;
      if(type == 'email') {
        if(!vm.sending) {
          vm.sending = true;
          this.axios({
            method: 'put',
            url: '/api/bulletins/' + vm.edit_data.id + '?test-email=' + vm.test_email
          })
          .then(function (response) {
            if(response.data.success) {
              vm.$parent.makeToast('success', 'Testiviesti (email)', 'Lisätty lähetysjonoon.');
            } else {
              vm.$parent.makeToast('warning', 'Testiviesti (email)', 'Lähetys epäonnistui!');
            }
            vm.sending = false;
          })
          .catch(function () {
            vm.sending = false;
            vm.$parent.makeToast('danger', 'Testiviesti (email)', 'Lähetys epäonnistui!');
          })
        }
      } else if(type == 'sms') {
        if(!vm.sending) {
          vm.sending = true;
          this.axios({
            method: 'put',
            url: '/api/bulletins/' + vm.edit_data.id + '?test-sms=' + vm.test_sms
          })
          .then(function (response) {
            if(response.data.success) {
              vm.$parent.makeToast('success', 'Testiviesti (sms)', 'Lisätty lähetysjonoon.');
            } else {
              vm.$parent.makeToast('warning', 'Testiviesti (sms)', 'Lähetys epäonnistui!');
            }
            vm.sending = false;
          })
          .catch(function () {
            vm.sending = false;
            vm.$parent.makeToast('danger', 'Testiviesti (sms)', 'Lähetys epäonnistui!');
          })
        }
      }
    },
		edititem: function (id) {
			this.edit = true;
			this.edit_data = Object.assign({}, this.items[id]);
      this.edit_data.message_start = this.$options.filters.formatInputDateTime(this.edit_data.message_start);
      this.edit_data.message_end = this.$options.filters.formatInputDateTime(this.edit_data.message_end);
		},
		canceledit: function () {
      this.load();
			this.edit = false;
		},
		newitem: function () {
			this.edit = true;
			this.edit_data = { name: '', is_draft: 1 }
		},
    sendmessage: function() {
      this.edit_data.send = 1;
      this.saveedit();
    },
		saveedit: function () {
			var vm = this;
			if(!vm.sending) {
				vm.sending = true;
				var method = 'put';
				if(typeof vm.edit_data.id == 'undefined') method = 'post';
				this.axios({
					method: method,
					url: '/api/bulletins',
					data: vm.edit_data
				})
				.then(function (response) {
					if(response.data.success) {
						if(typeof vm.edit_data.id == 'undefined' && response.data.object.id > 0) vm.edit_data.id = response.data.object.id;

						vm.$parent.makeToast('success', 'Tiedotteen tallennus', 'Onnistui!');
						vm.load();

            if(vm.edit_data.is_draft == 1 && response.data.object.is_draft == 0) {
              vm.edit_data.sent_by = response.data.object.sent_by;
              vm.edit_data.sent_by_name = response.data.object.sent_by_name;
              vm.edit_data.sent = response.data.object.sent;
              vm.edit_data.is_draft = 0;
            }

					} else {

						vm.$parent.makeToast('warning', 'Tiedotteen tallennus', 'Täytä pakolliset kentät!');
					}
					vm.sending = false;
				})
				.catch(function () {
					vm.sending = false;
					vm.$parent.makeToast('danger', 'Tiedotteen tallennus', 'Epäonnistui!');
				})
			}
		},
		senddelete: function () {
			var vm = this;
      vm.sending = true;
      vm.confirmDelete = false;
			this.axios({
				method: 'delete',
				url: '/api/bulletins',
				data: vm.edit_data
			})
			.then(function (response) {
				if(response.data.success) {
					vm.$parent.makeToast('success', 'Tiedotteen poisto', 'Onnistui!');
					vm.load();
					vm.edit = false;
				} else {
					vm.$parent.makeToast('danger', 'Tiedotteen poisto', 'Epäonnistui!');
				}
        vm.sending = false;
			})
			.catch(function () {
				vm.$parent.makeToast('danger', 'Tiedotteen poisto', 'Epäonnistui!');
        vm.sending = false;
			})
    },
    searchUsers: debounce( function (searchQuery) {
      var vm = this;
      if (searchQuery) {
        vm.msLoading = true;
        this.axios.get('/api/users?mode=simple&search=' + searchQuery)
        .then(function (response) {
          if(response.status == 200) {
            vm.users = response.data.results;
            vm.msLoading = false;
          } else {
            vm.users = [];
            vm.msLoading = false;
          }
        })
        .catch(function () {
          vm.users = [];
          vm.msLoading = false;
        })
      }
    }, 500 ),
    searchClients: debounce( function (searchQuery) {
      var vm = this;
      if (searchQuery) {
        vm.msLoading = true;
        this.axios.get('/api/users?mode=simple&type=clients&search=' + searchQuery)
        .then(function (response) {
          if(response.status == 200) {
            vm.clients = response.data.results;
            vm.msLoading = false;
          } else {
            vm.clients = [];
            vm.msLoading = false;
          }
        })
        .catch(function () {
          vm.clients = [];
          vm.msLoading = false;
        })
      }
    }, 500 )
	},
	watch: {
		page: function () {
			this.load();
		},
    sort: function () {
			this.load();
		},
	},
	computed: {
		getsmslength: function () {
			if(typeof this.edit_data.sms_body != 'undefined') return this.edit_data.sms_body.length;
			return 0;
		}
	}
}
</script>
